import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import classnames from 'classnames'
import React, { FC, FormEvent, MouseEvent } from 'react'
import styled from 'styled-components'

import magnifyingGlassIcon from '../../../img/magnifyingGlass.svg'
import { usePageUrl } from '../../../lib/pages'

export interface SearchProductsFormProps {
  className?: string
  loading: boolean
  searchInput?: string
  previousSearchValue: string
  setSearchInput: (input: string) => void
}

const SearchProductsForm: FC<SearchProductsFormProps> = ({
  className,
  loading,
  searchInput,
  setSearchInput,
}) => {
  const { navigate } = usePageUrl()
  const onSearch = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    // Appends the search input as a query string
    if (searchInput) {
      navigate(`/search-results?keywords=${encodeURIComponent(searchInput)}`)
    }
  }

  return (
    <form className={className} onSubmit={(event) => onSearch(event)}>
      <div className="search-input-wrapper">
        <input
          autoComplete="off"
          value={searchInput}
          disabled={loading}
          id="search-input"
          name="search-input"
          onChange={(event) => setSearchInput(event.target.value)}
          type="search"
        />
        <label className={classnames({ disabled: loading })} htmlFor="search-input">
          <img alt="Search for products" src={magnifyingGlassIcon} />
        </label>
      </div>
      <PrimaryButton type="submit" onClick={(event) => onSearch(event)} text="Search" />
    </form>
  )
}

SearchProductsForm.defaultProps = {
  className: '',
  searchInput: '',
}

const StyledSearchProductsForm = styled(SearchProductsForm)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  input: {
    backgroundColor: theme.colors.grayscale.white,
    border: `1px solid ${theme.colors.grayscale.black}`,
    borderRadius: '8px',
    color: theme.colors.grayscale.black,
    cursor: 'text',
    fontSize: '16px',
    height: '65px',
    lineHeight: '16px',
    padding: '8px 15px 0 50px',
    width: '100%',
    '&:disabled': {
      color: theme.colors.coolGray.cool450,
      cursor: 'not-allowed',
    },
    '&:focus': {
      border: `1px solid ${theme.colors.brand.blue}`,
      outline: 'none',
    },
  },
  label: {
    bottom: '0',
    color: theme.colors.coolGray.cool600,
    cursor: 'text',
    height: '65px',
    left: '15px',
    position: 'absolute',
    top: '20px',
    transition: '0.2s',
    '&.disabled': {
      color: theme.colors.coolGray.cool450,
      cursor: 'not-allowed',
    },
    img: {
      marginLeft: '10px',
      marginTop: '7px',
    },
  },
  [PrimaryButton]: {
    maxWidth: '200px',
    width: '100%',
  },
  '.search-input-wrapper': {
    marginBottom: '20px',
    maxWidth: '604px',
    position: 'relative',
    width: '100%',
    input: {
      width: '100%',
    },
  },
  '@media(min-width: 600px)': {
    flexDirection: 'row',
    justifyContent: 'center',
    '.search-input-wrapper': {
      marginBottom: '0',
      marginRight: '32px',
    },
  },
}))

export default StyledSearchProductsForm
