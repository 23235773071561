import React, { FC } from 'react'
import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import Head from '../../../layouts/Head'
import Layout from '../../global/Layout'
import SearchProductsContainer from './SearchProductsContainer'

export const SearchResultsPage: FC = () => {
  return (
    <>
      <Head title="Search Products" />
      <GlobalStyle includeTypography />
      <Layout>
        <SearchProductsContainer pageContext={{}} />
      </Layout>
    </>
  )
}
