import React, { useState, useEffect, FC } from 'react'
import styled from 'styled-components'

import SearchProductsForm from './SearchProductsForm'

export interface SearchProductsHeaderProps {
  className?: string
  anyItemsReturned: boolean
  loading: boolean
  search?: { keywords?: string } | null
}

const SearchProductsHeader: FC<SearchProductsHeaderProps> = ({
  className,
  anyItemsReturned,
  loading,
  search,
}) => {
  const previousSearchValue = search?.keywords || ''
  const [searchInput, setSearchInput] = useState(previousSearchValue)

  // Update search input if it changes from either the url or the header search bar
  useEffect(() => {
    setSearchInput(search?.keywords || '')
  }, [search])

  return (
    <header className={className}>
      {loading ? (
        <div className="placeholder">
          <h2>{searchInput ? `Searching for "${searchInput}"` : 'Searching for all products'}</h2>
        </div>
      ) : (
        <div>
          <h2>
            {anyItemsReturned
              ? previousSearchValue
                ? `Showing results for "${previousSearchValue}"`
                : 'Showing results for all products'
              : `Sorry, no results for "${previousSearchValue}"`}
          </h2>
          {!anyItemsReturned && <p>Try your search again:</p>}
        </div>
      )}
      <SearchProductsForm
        loading={loading}
        previousSearchValue={previousSearchValue}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </header>
  )
}

SearchProductsHeader.defaultProps = {
  className: '',
  search: null,
}

const StyledSearchProductsHeader = styled(SearchProductsHeader)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.grayscale.white,
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 20px',
  textAlign: 'center',
  h2: {
    marginBottom: '20px',
  },
  p: {
    marginBottom: '20px',
  },
  '.placeholder': {
    minHeight: '48px',
  },
}))

export default StyledSearchProductsHeader
