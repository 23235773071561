import React, { FC } from 'react'
import { Location, LocationContext } from '@gatsbyjs/reach-router'
import queryString, { ParsedQuery } from 'query-string'

export type WithLocationProps<TProps> = TProps & LocationContext & { search?: ParsedQuery | null }
export type WithLocationFC<TProps> = FC<WithLocationProps<TProps>>

const withLocation = <TProps extends Record<string, unknown>>(
  ComponentToWrap: WithLocationFC<TProps>
): FC<TProps> => {
  const LocationHOC: FC<TProps> = (props: TProps) => {
    return (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    )
  }
  return LocationHOC
}

export default withLocation
